<template>
  <v-row
    align-center
    justify-center
    class="docs ma-3"
  >
    <v-col
      xs8
    >
      <v-card class="pa-4 docs-card">
        <v-card-title primary-title>
          <div>
            <h1>Privacy Policy</h1>
          </div>
        </v-card-title>

        <v-card-text>
          <p>
            Outsourced Ad Ops LLC, a New York limited liability company
            (“OAO, “we,” or “us”), owns and operates the website (the “Website”)
            linked to this privacy policy (this “Privacy Policy”). The purpose of
            this Privacy Policy is to describe how OAO may treat and use
            information we may collect when you use the Website or OAO’s
            services. This Privacy Policy discloses what information we gather,
            how we use that information, what choices you have concerning the
            collection and disclosure of your personal information, and how you
            can correct or change that information. We recognize that information
            privacy is an ongoing responsibility, and we will update this Privacy
            Policy from time to time as we undertake new personal data practices
            or adopt new privacy policies.
          </p>

          <p>
            In summary, you should know that:
          </p>

          <p>
            This Privacy Policy only applies to information gathered from the
            Website. It does not apply to any other information collected or
            obtained through other means, or information collected by one of our
            customers on that customer’s own website or online properties.
          </p>

          <p>
            OAO collects personal information to provide its services, which
            involve the programming, tracking, recording, managing and publishing
            of advertisements on our customers’ websites. OAO does not collect
            any personally identifiable information, except for the information
            you provide through your direct input on the Website. We do collect
            information relating to your website usage and information from
            cookies, as described below. We do not knowingly attempt to solicit
            or receive information from children.
          </p>

          <p>
            OAO will not disclose your personal information to any third party
            without your consent, except as required to provide you with OAO’s
            services.
          </p>

          <p>
            OAO does not sell, rent, or share your personal information to any
            third party without your consent, except as required to provide you
            with OAO’s services.
          </p>

          <p>
            OAO has security measures in place to help keep your personal
            information secure.
          </p>

          <h2>
            PERSONAL INFORMATION
          </h2>

          <p>
            When we use the terms “personally identifiable information” and
            “personal information,” we mean information that identifies a
            particular individual, such as contact information (including full
            name, street address or email address); demographic information (such
            as age or gender); unique identifiers (such as your user ID or
            password); and financial information (such as your credit card number).
          </p>

          <h2>
            WEBSITE USAGE INFORMATION
          </h2>

          <p>
            Whenever you visit the Website, OAO may use cookies to receive and
            store certain types of internet usage information, such as your IP
            address and click-stream data, as well as information on how the
            Website is used, visit history, including section visits, operating
            system and browser type information. OAO will not collect data
            relating to sensitive data categories, such as past or current
            activity of browsers on gambling, adult, government agency, or
            child-oriented sites, personal health, race, religion, ethnicity,
            criminal record, political opinions or beliefs, union membership,
            sexual orientation or behavior. The usage information does not
            contain personally identifiable information, and will only be
            used to determine how the Website is being used and help us create a
            better, faster, more efficient Website and provide you with better
            content.
          </p>

          <h2>
            COOKIES, FLASH COOKIES, SERVER LOGS AND WEB BEACONS
          </h2>

          <p>
            Cookies are bits of data stored on your hard drive that contain no
            personal information but which help enhance your web-browsing
            experience. Not all web browsers accept cookies, and those that
            do usually provide a preference or setting that allows you to
            refuse and remove most types of browser cookies. You may opt-out
            of OAO’s data collection activities by turning off the cookies on
            your browser. If you change computers or browsers, or use
            multiple computers or browsers or delete your cookies, you will
            need to repeat this opt out process for each computer and each
            browser. If you visit iadops.com without cookies enabled, it will
            turn off some features of the site.
          </p>

          <p>
            Some servicers may use Flash cookies, or local shared objects, to
            store preferences or personalize content. Similarly, some
            services use web beacons, which are electronic images that
            deliver cookies, count visits, determine if emails have been
            opened, or evaluate usage. Server logs similarly capture
            information, such as the time and date of the visit to the
            Website, the browser and the operating system being used and
            more. OAO does not use Flash cookies, web beacons or server logs
            to gather data on its Website.
          </p>

          <p>
            For more information regarding online advertising, cookies and their
            use, you can visit http://www.aboutads.info and/or
            http://www.aboutads.info/consumers.  For additional information on
            how to opt out of data collection, you can visit
            http://www.aboutads.info/choices/ and
            http://www.aboutads.info/consumers#browsers.
          </p>

          <h2>
            USE OF COLLECTED INFORMATION
          </h2>

          <p>
            Any information collected via the Website is owned by OAO and we may
            use it for our own purposes, including marketing. Such
            information may also be used to contact you about the Website;
            provide you with services or information you have requested or
            ordered; monitor or improve the use of the Website; customize the
            advertising and content you see; and monitor compliance with our
            terms and conditions of use of the Website. Further, to the
            extent OAO does collect any information via the Website, OAO does
            not share any such information with any other website or entity.
          </p>

          <h2>
            THIRD PARTY AND CUSTOMER SITES
          </h2>

          <p>
            You should be aware that when you are on the Website you could be
            directed to other websites beyond our control, which may include
            websites of OAO’s customers, advertisers or market partners. Even
            if those websites reflect the services of OAO, or have the look
            and feel of the OAO Website, any interaction on that website will
            be with the owner of that website, and that party’s privacy
            policy will govern any information gathered. These other websites
            may independently collect data, use cookies or solicit personal
            information and may or may not have their own published privacy
            policies. If you visit a website that is linked to the Website,
            you should consult that website’s privacy policy before providing
            any personal information. All websites utilizing OAO’s services
            are required to have such a privacy policy.
          </p>

          <h2>
            OAO ALPHA COLLECTED INFORMATION
          </h2>

          <p>
            In addition to the foregoing concerning customer websites, OAO may
            collect, or work with third party technologies that collect,
            information about visitors to OAO’s customer websites (“OAO Alpha
            Collected Information”). OAO Alpha Collected Information includes,
            for example:
          </p>

          <p>
            IP address, browser type, browser history, ISP, browser language,
            referring website addresses, date and time of visits, search
            keywords, page view history, activities and actions on the
            customer website, user location (city, country, zip code), date
            and time stamp, types of advertisements viewed and click data,
            and visitor’s device information, including make, model,
            operating system, and data connection type.
          </p>

          <p>
            OAO may use the OAO Alpha Collected Information to better target
            advertising and advertising campaigns to visitors of OAO’s customer
            websites. The information may also be used to operate and improve
            OAO’s services; to customize the advertising and content you see
            on the customer website; to report on or forecast advertisement
            or advertisement campaign performance, including preparing
            visitor activity summaries; and to determine user responses to
            advertisements and advertisement campaigns. OAO may supplement
            the OAO Alpha Collected Information with information provided by
            third parties to enhance these uses. OAO may share the OAO Alpha
            Collected Information with its partners to help them deliver
            targeted advertisements and advertising campaigns to visitors to
            customer websites and with those who help OAO provide its
            services. OAO may also share the OAO Alpha Collected Information
            as necessary to comply with laws or regulatory requirements and
            to respond to lawful requests and legal process, to protect the
            rights and property of OAO, its affiliates, agents and customers
            or in the case of an emergency to protect the safety of our
            employees, agents, customers or any person.
          </p>

          <h2>
            QUESTIONS ABOUT THIS PRIVACY POLICY
          </h2>

          <p>
            OAO is headquartered in New York, in the United States of America (
            “America”). If you have any questions about this Privacy Policy or
            the privacy practices of the Website, please contact us by email at
            privacy@adops.com or by mail at:
          </p>

          <pre>
          OAO
          451 Broadway, 3rd Floor
          New York, NY 10013
          Attention: Privacy Group
          </pre>

          <h2>
            TRANSFERRING PERSONAL DATA FROM THE EU TO THE US
          </h2>

          <p>
            OAO has its headquarters in America, and information we collect from
            you will be processed there. America has neither sought nor received
            a finding of “adequacy” from the European Union under Article 45 of
            the General Data Policy Regulation (the “GDPR”). OAO relies on
            derogations for specific situations as set forth in Article 49 of the
            GDPR. In particular, OAO collects and transfers to America personal
            data only: with your consent; to perform a contract with you; or to
            fulfill a compelling legitimate interest of OAO in a manner that does
            not outweigh your rights and freedoms. OAO endeavors to apply
            suitable safeguards to protect the privacy and security of your
            personal data and to use it only consistent with your relationship
            with OAO and the practices described in this Privacy Policy. We also
            minimize the risk to your rights and freedoms by not collecting or
            storing sensitive information about you.
          </p>

          <h2>
            DATA SUBJECT RIGHTS
          </h2>

          <p>
            If you wish to confirm that we are processing your personal data, or
            to have access to the personal data we may have about you, please
            contact us at privacy@adops.com.
          </p>

          <p>
            CHOICE/OPT-OUT: We provide you with the opportunity to opt-out of
            receiving communications from us at any point where we request
            information. If you do not want to receive future communications from
            us, please contact us by sending an email to privacy@adops.com.
          </p>

          <p>
            CORRECT/UPDATE. If you want to change or modify information you have
            previously provided to us, please contact us by sending an email to
            privacy@adops.com.
          </p>

          <h2>
            DATA STORAGE AND RETENTION
          </h2>

          <p>
            We store your personal data on our servers, and on the servers of the
            cloud-based database management services we engage, located in
            America. We retain data for as long as needed to provide our
            services, and as necessary to comply with our legal obligations,
            resolve disputes, and enforce our agreements. For more
            information on where and how long your personal data is stored,
            and for more information on your rights of erasure and
            portability, please contact us by email or by mail at the
            addresses indicated above.
          </p>

          <h2>
            CHANGE OF OWNERSHIP OR CONTROL
          </h2>

          <p>
            If we are subject to a merger or acquisition with/by another company,
            or if we experience some other change of control event, any or all
            user-related information may be transferred in connection with such
            change of ownership or control event. While we cannot guarantee that
            any entity receiving such information in connection with any of these
            transactions will comply with all the terms of this Privacy Policy,
            we will advise the new entity that it is required to follow this or a
            similar privacy policy that complies with all applicable laws
            relating to your personal information.
          </p>

          <h2>
            ADVERTISING
          </h2>

          <p>
            We do not allow third parties to track or collect your personal
            information on our sites for their own advertising purposes without
            your explicit consent.
          </p>

          <h2>
            DO NOT TRACK
          </h2>

          <p>
            California law requires us to advise you how we respond to web
            browser Do Not Track (DNT) signals. As there currently is no industry
            or legal standard for recognizing or honoring DNT signals, we do not
            respond to them at this time. We await the results of work by the
            privacy community and industry to determine when such a response is
            appropriate and what form the response should take.
          </p>

          <h2>
            CHILDREN’S PRIVACY
          </h2>

          <p>
            Our websites are general audience websites and not intended for
            children. We do not knowingly collect personal information from users
            deemed to be children under their respective national laws.
          </p>

          <h2>
            UPDATES/EFFECTIVE DATE
          </h2>

          <p>
            OAO reserves the right to amend this Privacy Policy from time to
            time. Changes to this Privacy Policy will apply to information we
            have received and information we receive after the revised Privacy
            Policy is effective. When we do amend this Privacy Policy, the
            revised Policy will be posted here so please visit this Web page to
            view the most current Privacy Policy. The effective date of this
            version of the Privacy Policy is May 23, 2018.
          </p>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
}
</script>

<style lang="scss" scoped>
@import "~src/styles/variables";

pre {
  background: none;
  color: $color-monochrome-gray;
}

h2 {
  font-size: medium;
  margin: 3ch 0 1ch 0;
}
</style>
